import ACTIONS from "store/actionTypes";
import LocalStorage from "services/localStorage";

export const authData = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN_SUCCESS:
      return action.authData;
    case ACTIONS.LOGIN_FAILURE:
      return {};
    case ACTIONS.UPDATE_INFO:
      const account = {
        ...state.account,
        ...action.data,
      };

      LocalStorage.set("account", JSON.stringify(account));
      return { ...state, account };
    case ACTIONS.GET_PERMISSIONS_SUCCESS:
      return { ...state, permissions: action.permissions };
    case ACTIONS.LOGOUT:
      return { authenticated: false };
    default:
      return state;
  }
};

const notificationInitial = { message: "", appearance: "success" };
export const notification = (state = notificationInitial, action) => {
  if (action.exception) {
    return { message: action.exception, appearance: "error" };
  }

  switch (action.type) {
    case ACTIONS.SHOW_NOTIFICATION:
      return {
        message: action.message,
        appearance: action.appearance || "success",
      };
    case ACTIONS.SHOW_NOTIFICATION_SUCCESS:
      return {
        message: action.message,
        appearance: "success",
      };
    case ACTIONS.SHOW_NOTIFICATION_FAILURE:
      return {
        message: action.message,
        appearance: "error",
      };
    case ACTIONS.RESET_NOTIFICATION:
      return notificationInitial;
    default:
      return state;
  }
};

const contentTypesInitial = { list: [] };
export const contentTypes = (state = contentTypesInitial, action) => {
  switch (action.type) {
    case ACTIONS.CONTENT_TYPES_REQUEST:
      return {
        pending: true,
        list: [],
      };
    case ACTIONS.CONTENT_TYPES_SUCCESS:
      return {
        pending: false,
        failed: false,
        list: action.list,
      };
    case ACTIONS.CONTENT_TYPES_FAILURE:
      return {
        failed: true,
        list: action.list,
      };
    default:
      return state;
  }
};

export const loading = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.LOADING_START:
      return true;
    case ACTIONS.LOADING_FINISH:
      return false;
    default:
      return state;
  }
};

export const messages = (state = [], action) => {
  switch (action.type) {
    case ACTIONS.GET_MESSAGES_SUCCESS:
      return action.messages;
    case ACTIONS.SEND_MESSAGE_SUCCESS:
      const msgIndex = state.findIndex(
        (conv) => conv.partner_id === action.message?.to
      );
      const newState = [...state];

      newState[msgIndex]?.messages.push(action.message);

      return newState;
    default:
      return state;
  }
};
