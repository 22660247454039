import { fork, put, call, delay, select, take } from "redux-saga/effects";
import { callApi } from "./callApi";
import config from "../../configs/parameters";
import actionTypes from "../actionTypes";
import { getAuthenticationStatus, getAuthenticationToken } from "../selectors";
import { successMessages } from "store/actions/messages";
import { proxy } from "services/apiService";

function* getMessages() {
  try {
    const token = yield select(getAuthenticationToken);
    const headers = { Authorization: "JWT " + token };
    const { data } = yield call(
      proxy.getConversations,
      "getConversations",
    );

    yield put(successMessages({ messages: data.result }));
  } catch (e) {}
}

export function* fetchMessagesByInterval() {
  while (true) {
    const isAuthenticated = yield select(getAuthenticationStatus);
    if (isAuthenticated) {
      yield fork(getMessages);
    }
    yield delay(config.fetchIntervals.messages);
  }
}

export function* watchLoginToFetchMessages() {
  while (true) {
    yield take([
      actionTypes.LOGIN_SUCCESS,
      actionTypes.GET_MESSAGES_REQUEST,
    ]);
    yield fork(getMessages);
  }
}
