import React, { useCallback } from "react";
import { Button, Col, Row, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { logout } from "store/actions/login";
import { useHistory } from "react-router";

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = useCallback(() => {
    return dispatch(logout());
  }, [history]);

  return (
    <Container className="mt-2">
      <Row className="justify-content-end align-items-center text-center">
        <Col sm={2}>
          <Button variant="outline-primary" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            &nbsp; Log out
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Logout;
