import { useSelector } from "react-redux";
import { getUnreadMessagesCount } from "store/selectors";

import Classes from "./UnreadMessagesBadge.module.scss";

export const AllUnreadMessages = () => {
  const count = useSelector(getUnreadMessagesCount);

  if (!count) {
    return "";
  }

  return <UnreadMessagesBadge count={count} />;
};

const UnreadMessagesBadge = ({ count }) => {
  if (!count) {
    return "";
  }

  return (
    <div className={Classes.container}>
      <span className={Classes.badge}>{count}</span>
    </div>
  );
};

export default UnreadMessagesBadge;
