import { take, fork, put, call, cancel } from "redux-saga/effects";
import { loginFail, loginSuccess, logout } from "store/actions/login";
import types from "store/actionTypes";
import { callApi } from "store/sagas/callApi";
import LocalStorage from "services/localStorage";
import { accountRoleIdsByName as Roles } from "configs/parameters";
import { proxy } from "services/apiService";

function* processLogin({ token }) {
  try {
    if (token) {
      const response = yield call(callApi, `authenticate`, "POST", { token });
      const { data } = response;
      if (data.success) {
        const { account, permissions } = data;
        if (![Roles.admin, Roles.manager].includes(account.role_id)) {
          LocalStorage.empty();
          return yield put(loginFail({ exception: "Permission denied!" }));
        }

        LocalStorage.set("token", data.token);
        LocalStorage.set("account", JSON.stringify(account));
        LocalStorage.set("permissions", JSON.stringify(permissions));
        return yield put(
          loginSuccess({
            authenticated: true,
            token: data.token,
            account,
            permissions,
          })
        );
      }

      yield put(loginFail({ exception: data.message }));
    } else {
      const localToken = LocalStorage.get("token");
      const permissions = LocalStorage.get("permissions");
      let localAccount = LocalStorage.get("account");

      if (localToken && localAccount) {
        try{
          localAccount = JSON.parse(localAccount);
          const data = { member_id: Number(localAccount.member_id) };
          const response = yield call(proxy.getAccount, `getAccount`, data);
          LocalStorage.set("account", JSON.stringify(response.data.result));
  
          yield put(
            loginSuccess({
              authenticated: true,
              token: localToken,
              account: response.data.result,
              permissions: JSON.parse(permissions),
            })
          );
        }catch(e){
          console.log(e)
          yield put(logout());
        }
      } else {
        yield put(logout());
      }
    }
  } catch (e) {
    yield put(
      loginFail({ exception: e?.response?.data?.message || e.message })
    );
  }
}

export function* watchLogin() {
  let forked = null;
  while (true) {
    const action = yield take(types.LOGIN_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processLogin, action);
  }
}

export function* watchLogout() {
  while (true) {
    yield take(types.LOGOUT);
    LocalStorage.empty();
  }
}
