import React from "react";
import Routes from "./Routes";
import { ToastProvider } from "react-toast-notifications";
import { Provider } from "react-redux";
import Notification from "components/Notification";
import { BrowserRouter as Router } from "react-router-dom";
import storeFactory from "store";

const store = storeFactory();

const App = () => (
  <Provider store={store}>
    <ToastProvider autoDismiss autoDismissTimeout={3500}>
      <Router>
        <Routes />
      </Router>
      <Notification />
    </ToastProvider>
  </Provider>
);

export default App;
