import { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { resetNotification } from "store/actions/notification";
import { getNotification } from "store/selectors";

const Notification = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const notification = useSelector(getNotification);

  useEffect(() => {
    if (notification.message) {
      addToast(notification.message, { appearance: notification.appearance });
      setTimeout(() => {
        dispatch(resetNotification());
      }, 3500);
    }
  }, [notification]);

  return false;
};

export default Notification;
