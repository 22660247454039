import { take, fork, put, call, cancel, select } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import { callApi } from "./callApi";
import { getAccount } from "store/selectors";
import {
  getPermissionsFailure,
  getPermissionsSuccess,
} from "store/actions/permisssions";
import { proxy } from "services/apiService";

function* processGetPermissionsRequest() {
  try {
    const { member_id } = yield select(getAccount);

    const { data: response } = yield call(
      proxy.getPermissions,
      "getPermissions",
      { member_id }
    );

    if (!response.success) {
      return yield put(
        getPermissionsFailure({ exception: response.error?.message })
      );
    }

    yield put(
      getPermissionsSuccess({
        permissions: response.result.permissions,
      })
    );
  } catch (e) {
    if (!!e && !!e.response) {
      yield put(getPermissionsFailure({ exception: e.response }));
    }
  }
}

export function* watchPermissions() {
  while (true) {
    yield take([actionTypes.LOGIN_SUCCESS]);
    yield fork(processGetPermissionsRequest);
  }
}
