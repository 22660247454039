import React from "react";
import { faTree } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "./GrowWithTrees.module.scss";

const GrowWithTrees = () => (
  <div className={Classes.container}>
    <div className={Classes.gwt}>
      <div>Grow With Trees</div>
      <FontAwesomeIcon icon={faTree} />
    </div>
    <div className={Classes.loginPrompt}>Please login to continue</div>
  </div>
);

export default GrowWithTrees;
