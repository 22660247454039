module.exports = {
  READ_CONTENT: "read:content",
  READ_CUSTOMER: "read:customer",
  READ_ECOREGION: "read:ecoregion",
  READ_GAME: "read:game",
  READ_MEMBER: "read:member",
  READ_PLANT: "read:plant",
  READ_ACTIVITY_LOGS: "read:activity_logs",
  READ_MESSAGE: "read:message",
  ADD_CONTENT: "add:content",
  ADD_CUSTOMER: "add:customer",
  ADD_ECOREGION: "add:ecoregion",
  ADD_MEMBER: "add:member",
  ADD_PLANT: "add:plant",
  ADD_GAME_QUESTION: "add:game:question",
  ADD_GAME_CONTENT: "add:game:content",
  EDIT_CONTENT: "edit:content",
  EDIT_ECOREGION: "edit:ecoregion",
  EDIT_MEMBER: "edit:member",
  EDIT_CUSTOMER: "edit:customer",
  EDIT_PLANT: "edit:plant",
  EDIT_GAME_QUESTION: "edit:game:question",
  EDIT_GAME_CONTENT: "edit:game:content",
  EDIT_GAME_LEADERBOARD: "edit:game:leaderboard",
  EXPORT_MEMBERS: "export:members",
  EXPORT_ACTIVITY_LOGS: "export:activity_logs",
  EXPORT_LEADERBOARD: "export:game:leaderboard",
  EXPORT_PLANTS: "export:plants",
  DELETE_MEMBER: "delete:member",
  DELETE_CUSTOMER: "delete:customer",
  DELETE_CONTENT: "delete:content",
  DELETE_PLANT: "delete:plant",
  DELETE_GAME_QUESTION: "delete:game:question",
  DELETE_GAME_CONTENT: "delete:game:content",
  EDIT_PLANT_IDENTIFY: "edit:customer:plant_identify",
};
