import React, { useState } from "react";
import {
  faTree,
  faUsers,
  faFileAlt,
  faMapMarkerAlt,
  faArrowLeft,
  faTrophy,
  faBuilding,
  faThList,
  faTachometerAlt,
  faCog,
  faEnvelope,
  faListAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { appUrl } from "configs/parameters";
import { AllUnreadMessages } from "components/UnreadMessagesBadge";
import User from "./User";
import AccessControl from "components/common/AccessControl";

import PERMISSIONS from "configs/permissions";

const navBarItems = [
  {
    path: "/plants",
    title: "Plants",
    icon: faTree,
    permissions: [PERMISSIONS.READ_PLANT],
  },
  {
    path: "/accounts",
    title: "Accounts",
    icon: faUsers,
    permissions: [PERMISSIONS.READ_MEMBER],
  },
  {
    path: "/customers",
    title: "Customers",
    icon: faBuilding,
    permissions: [PERMISSIONS.READ_CUSTOMER],
  },
  {
    path: "/contents",
    title: "Content",
    icon: faFileAlt,
    permissions: [PERMISSIONS.READ_CONTENT],
  },
  {
    path: "/regions",
    title: "Eco Regions",
    icon: faMapMarkerAlt,
    permissions: [PERMISSIONS.READ_ECOREGION],
  },
  {
    path: "/messages",
    title: "Inbox",
    icon: faEnvelope,
    permissions: [PERMISSIONS.READ_MESSAGE],
    component: AllUnreadMessages,
  },
  {
    title: "Game",
    key: "game",
    icon: faTrophy,
    nested: true,
    permissions: [PERMISSIONS.READ_GAME],
    subItems: [
      { path: "/game/contents", title: "Content", icon: faFileAlt },
      { path: "/game/questions", title: "Questions", icon: faThList },
      {
        path: "/game/leaderboard",
        title: "Leaderboard",
        icon: faTachometerAlt,
      },
      { path: "/game/settings", title: "Settings", icon: faCog },
    ],
  },
  {
    path: "/activity-log",
    title: "Activity Log",
    icon: faListAlt,
    permissions: [PERMISSIONS.READ_ACTIVITY_LOGS],
  },
  {
    path: "/notification-images",
    title: "Notification Images",
    icon: faListAlt,
    permissions: [PERMISSIONS.READ_ACTIVITY_LOGS],
  },
];

const SideBarItem = ({ item }) => (
  <AccessControl allowedPermissions={item.permissions}>
    {item.icon && <FontAwesomeIcon icon={item.icon} />}
    &nbsp;
    {item.title}
    &nbsp;
    {item.component && <item.component />}
    <div>
      <ul className="nav flex-column">
        <SideBarItems itemList={item.subItems} isSubItem />
      </ul>
    </div>
  </AccessControl>
);

const SideBarItems = ({ itemList = [], isSubItem }) => {
  const [openId, setOpenId] = useState(false);

  const handleOpenSubMenu = (key) => {
    setOpenId(key === openId ? "" : key);
  };

  return itemList.map((item, index) => (
    <AccessControl allowedPermissions={item.permissions} key={index}>
      <li
        onClick={() => handleOpenSubMenu(item.key)}
        className={`nav-item ${isSubItem ? "" : "px-5"} text-nowrap`}
      >
        {item.nested ? (
          <div className="nav-link active" aria-current="page">
            <SideBarItem item={item} />
          </div>
        ) : (
          <Link className="nav-link active" aria-current="page" to={item.path}>
            <SideBarItem item={item} />
          </Link>
        )}
      </li>
    </AccessControl>
  ));
};

const Sidebar = () => (
  <div className="sidebar-holder p-3 shadow-sm">
    <ul className="nav flex-column w-100">
      <li className="p-3 text-nowrap nav-item ">
        <User />
      </li>
      <li className="p-3 text-nowrap text-center nav-item ">
        <a href={appUrl}>
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className="p-2">Back to app</span>
        </a>
      </li>
      <li className="nav-item px-4">
        <Link to="/plants">
          <div className="p-2 text-nowrap text-center">Grow With Trees</div>
        </Link>
      </li>
      <SideBarItems itemList={navBarItems} />
    </ul>
  </div>
);

export default Sidebar;
