import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPermissions, getAccountRole } from "store/selectors";
import { accountRoleIdsByName } from "configs/parameters";

const DEFAULT_ROUTE = "/plants";

const checkPermissions = ({
  userPermissions,
  allowedPermissions,
  userRoleId,
}) => {
  if (userRoleId === accountRoleIdsByName.admin) {
    return true;
  }
  if (!allowedPermissions || allowedPermissions.length === 0) {
    return true;
  }
  if (!userPermissions || userPermissions.length === 0) {
    return false;
  }

  return allowedPermissions.every((permission) =>
    userPermissions.includes(permission)
  );
};

const useHasAccess = (allowedPermissions = []) => {
  const userPermissions = useSelector(getPermissions);
  const userRoleId = useSelector(getAccountRole);

  return checkPermissions({
    userPermissions,
    allowedPermissions,
    userRoleId,
  });
};

const AccessControl = ({ allowedPermissions, children, NoAccess = null }) => {
  const permitted = useHasAccess(allowedPermissions);
  if (permitted) {
    return children;
  }
  return NoAccess;
};

export const ProtectedRoute = ({ allowedPermissions, component, ...props }) => {
  const permitted = useHasAccess(allowedPermissions);
  const Component = component;
  return (
    <Route
      {...props}
      render={(props) =>
        permitted ? <Component {...props} /> : <Redirect to={DEFAULT_ROUTE} />
      }
    />
  );
};

export default AccessControl;
