import ACTIONS from "store/actionTypes";

export const getContentTypesSuccess = (list) => ({
  type: ACTIONS.CONTENT_TYPES_SUCCESS,
  list,
});

export const getContentTypesFailure = (e) => ({
  type: ACTIONS.CONTENT_TYPES_FAILURE,
  exception: e,
});
