import { lazy } from 'react';

const Plant = lazy(() => import("components/Plants/Plant"));
const PlantList = lazy(() => import("components/Plants/List"));
const Customer = lazy(() => import("components/Customers/Customer"));
const CustomerList = lazy(() => import("components/Customers/List"));
const AccountList = lazy(() => import("components/Accounts/List"));
const Account = lazy(() => import("components/Accounts/Account"));
const ActivityLog = lazy(() => import("components/ActivityLog"));
const ContentList = lazy(() => import("components/Content/List"));
const Content = lazy(() => import("components/Content/Content"));
const RegionList = lazy(() => import("components/Regions/List"));
const Region = lazy(() => import("components/Regions/Region"));
const GameRoutes = lazy(() => import("components/Game"));
const Messages = lazy(() => import("components/Messages"));
const permissions = lazy(() => import("configs/permissions"));
const NotificationImages = lazy(()=> import('components/NotificationImages'));


const routes = [
  {
    component: Plant,
    path: "/plants/create",
    exact: false,
    permissions: [permissions.ADD_PLANT],
  },
  {
    component: Plant,
    path: "/plants/edit/:plant_id",
    exact: false,
    permissions: [permissions.EDIT_PLANT],
  },
  {
    component: PlantList,
    path: "/",
    exact: true,
    permissions: [permissions.READ_PLANT],
  },
  {
    component: PlantList,
    path: "/plants",
    exact: true,
    permissions: [permissions.READ_PLANT],
  },
  {
    component: Customer,
    path: "/customers/create",
    exact: false,
    permissions: [permissions.ADD_CUSTOMER],
  },
  {
    component: Customer,
    path: "/customers/edit/:customer_id",
    exact: false,
    permissions: [permissions.EDIT_CUSTOMER],
  },
  {
    component: CustomerList,
    path: "/customers",
    exact: true,
    permissions: [permissions.READ_CUSTOMER],
  },
  {
    component: AccountList,
    path: "/accounts",
    exact: true,
    permissions: [permissions.READ_MEMBER],
  },
  {
    component: Account,
    path: "/accounts/create",
    exact: false,
    permissions: [permissions.ADD_MEMBER],
  },
  {
    component: Account,
    path: "/accounts/edit/:member_id",
    exact: false,
    permissions: [permissions.ADD_MEMBER],
  },
  {
    component: ActivityLog,
    path: "/activity-log",
    exact: true,
    permissions: [permissions.READ_ACTIVITY_LOGS],
  },
  {
    component: NotificationImages,
    path: "/notification-images",
    exact: true,
    permissions: [permissions.READ_ACTIVITY_LOGS],
  },
  {
    component: ContentList,
    path: "/contents",
    exact: true,
    permissions: [permissions.READ_CONTENT],
  },
  {
    component: Content,
    path: "/contents/create",
    exact: false,
    permissions: [permissions.ADD_CONTENT],
  },
  {
    component: Content,
    path: "/contents/edit/:content_id",
    exact: false,
    permissions: [permissions.EDIT_CONTENT],
  },
  {
    component: RegionList,
    path: "/regions",
    exact: true,
    permissions: [permissions.READ_ECOREGION],
  },
  {
    component: Region,
    path: "/regions/create",
    exact: false,
    permissions: [permissions.ADD_ECOREGION],
  },
  {
    component: Region,
    path: "/regions/edit/:eco_region_id",
    exact: false,
    permissions: [permissions.EDIT_ECOREGION],
  },
  {
    component: GameRoutes,
    path: "/game",
    exact: false,
    permissions: [permissions.READ_GAME],
  },
  {
    component: Messages,
    path: "/messages",
    exact: false,
    permissions: [permissions.READ_MESSAGE],
  },
];

export default routes;
