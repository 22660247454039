import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { ProtectedRoute } from "components/common/AccessControl";
import routes from "./routes";

const DEFAULT_ROUTE = "/plants";

const Private = () => (
  <Switch>
    {routes.map(
      ({ component, path = "/", exact = false, permissions }, index) => (
        <Route
          key={index}
          exact={exact}
          path={path}
          component={component}
          allowedPermissions={permissions}
        />
      )
    )}
    <Redirect to={DEFAULT_ROUTE} />
  </Switch>
);

export default Private;
