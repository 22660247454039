import { apiUrl, apiRoutes } from "../configs/parameters";
import axios from "axios";
import {
  showFailureNotification,
  showSuccessNotification,
} from "store/actions/notification";
import { getContentTypesSuccess } from "store/actions/content";
import { finishLoading, startLoading } from "store/actions/loading";
import { sendMessageSuccess } from "store/actions/messages";
import { getAuthenticationToken } from "helpers/localStorage";

const getHeaders = (token) => ({
  headers: {
    "Content-Type": "application/json",
    ...(token ? { Authorization: `JWT ${token}` } : ""),
    "Application-Name": "GWT-ADMIN",
  },
});

const generateUrlQuery = (params) => {
  if (!params) return "";
  let query = "?";
  for (const key in params) {
    if (params[key]) {
      if (Array.isArray(params[key])) {
        const values = params[key];
        values.forEach((val) => (query += `${key}[]=${val}&`));
      } else {
        query += `${key}=${params[key]}&`;
      }
    }
  }

  return query;
};

const getFullUrl = (route, params) => {
  return `${apiUrl}${apiRoutes[route]}`;
};


const api = axios.create({});




const methods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const createCall = (endpoint, method = methods.GET, data, opt = {}) => {
  const token = getAuthenticationToken();
  const headers = getHeaders(token);
  const options = {
    url: endpoint,
    method,
    data,
    ...opt,
    ...headers,
  };
  return api(options);
};

export const proxy = {
  getCustomers: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  unlinkAccountAndCustomer: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  linkAccountAndCustomer: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  updateAccount: (key, params) => {
    const { member_id } = params;
    return createCall(`${getFullUrl(key)}/${member_id}`, methods.PUT, params);
  },
  createAccount: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  resetPassword: (key, params) => {
    return createCall(getFullUrl(key), methods.PATCH, params);
  },
  resetQuiz:(key, params)=>{
    return createCall(getFullUrl(key), methods.POST, params);
  },
  getAccount: (key, params) => {
    const { member_id } = params;
    return createCall(`${getFullUrl(key)}/${member_id}`);
  },
  getAccounts: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  deleteAccount: (key, params) => {
    const { member_id } = params;
    return createCall(`${getFullUrl(key)}/${member_id}`, methods.DELETE);
  },
  toggleGame: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },

  getPermissions: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  togglePermission: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  uploadCSV: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params)
  },

  getActivityLog: (key, params) => {
    return createCall(getFullUrl(key), methods.GET,null, { params });
  },

  toggleContentCustomer: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },

  deleteContent: (key, params) => {
    const { content_id } = params;
    return createCall(`${getFullUrl(key)}/${content_id}`, methods.DELETE);
  },

  getPlants: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },

  getQuizContents: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  getQuizContent: (key, params) => {
    const { quiz_content_id } = params;
    return createCall(`${getFullUrl(key)}/${quiz_content_id}`);
  },

  getLeaderboard: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  duplicateQuizQuestion: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  getQuizQuestions: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  updateQuizQuestion: (key, params) => {
    return createCall(getFullUrl(key), methods.PUT, params);
  },
  getPlants: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  createQuizQuestion: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },

  updateQuizAnswer: (key, params) => {
    const { quiz_answer_id } = params;
    return createCall(
      `${getFullUrl(key)}/${quiz_answer_id}`,
      methods.PUT,
      params
    );
  },
  createQuizAnswer: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  getQuizQuestionAnswers: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  getQuizList: (key, {customer_id}) => {
    return createCall(`${getFullUrl(key)}/${customer_id}`);
  },
  addOrUpdateQuiz: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  
  readMessageThread: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  createMessage: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  forwardMessage: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  getPlants: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },

  deletePlant: (key, params) => {
    const { plant_id } = params;
    return createCall(`${getFullUrl(key)}/${plant_id}`, methods.DELETE);
  },

  updatePlant: (key, params) => {
    return createCall(getFullUrl(key), methods.PUT, params);
  },
  createPlant: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  getPlant: (key, params) => {
    const { plant_id } = params;
    return createCall(`${getFullUrl(key)}/${plant_id}`);
  },

  unlinkRegionAndPlant: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  linkRegionAndPlant: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  getPlantRegions: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  getRegions: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  deleteRegion: (key, params) => {
    const { eco_region_id } = params;
    return createCall(`${getFullUrl(key)}/${eco_region_id}`, methods.DELETE);
  },
  getRegion: (key, params) => {
    const { eco_region_id } = params;
    return createCall(`${getFullUrl(key)}/${eco_region_id}`, methods.GET);
  },
  updateRegion: (key, params) => {
    return createCall(getFullUrl(key), methods.PUT, params);
  },
  createRegion: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  notify: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },

  uploadImages: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },

  getPlantSections: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  updatePlantSection: (key, params) => {
    const { section_id } = params;
    return createCall(`${getFullUrl(key)}/${section_id}`, methods.PUT, params);
  },
  deletePlantSection: (key, params) => {
    const { section_id } = params;
    return createCall(`${getFullUrl(key)}/${section_id}`, methods.DELETE);
  },
  getContents: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  getContentsByType: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  getContentTypes: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  getContent: (key, params) => {
    const { content_id } = params;
    return createCall(`${getFullUrl(key)}/${content_id}`);
  },
  createContent: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  updateContent: (key, params) => {
    const { content_id, created_at, ...body } = params;
    return createCall(`${getFullUrl(key)}/${content_id}`, methods.PUT, body);
  },
  deleteImage: (key, params) => {
    const {section_image_id, content_image_id} = params;

    return createCall(`${getFullUrl(key)}/${section_image_id || content_image_id}`, methods.DELETE, params);
  },
  updateImageInfo: (key, params) => {
    return createCall(getFullUrl(key), methods.PUT, params);
  },

  createCustomer: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  deleteCustomer: (key, params) => {
    const { customer_id } = params;
    return createCall(`${getFullUrl(key)}/${customer_id}`, methods.DELETE);
  },
  getCustomer: (key, params) => {
    const { customer_id } = params;
    return createCall(`${getFullUrl(key)}/${customer_id}`);
  },
  updateCustomer: (key, params) => {
    return createCall(getFullUrl(key), methods.PUT, params);
  },
  getPlantCustomers: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  unlinkPlantFromCustomer: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  linkPlantToCustomer: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  duplicatePlant: (key, params) => {
    const { plant_id } = params;
    return createCall(`${getFullUrl(key)}/${plant_id}`, methods.POST);
  },
  getConversations: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, { params });
  },
  deleteMessage: (key, params) => {
    const {message_id} = params
    return createCall(`${getFullUrl(key)}/${message_id}`, methods.DELETE, {});
  },
  getNotificationImage: (key, params) => {
    return createCall(getFullUrl(key), methods.GET, null, {params});
  },

  createPlantSection: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },
  createQuizContent: (key, params) => {
    return createCall(getFullUrl(key), methods.POST, params);
  },

  updateQuizContent: (key, params) => {
    return createCall(getFullUrl(key), methods.PATCH, params);
  },

  deleteQuizContent: (key, params) => {
    return createCall(getFullUrl(key), methods.DELETE, params);
  },
  getQuizQuestion: (key, {quiz_question_id}) => {
    return createCall(`${getFullUrl(key)}/${quiz_question_id}`, methods.GET);
  },
  
  linkQuizQuestionAndCustomer:(key, params)=>{
    return createCall(getFullUrl(key), methods.POST, params);
  },
  
  unlinkQuizQuestionAndCustomer: (key, params)=>{
    return createCall(getFullUrl(key), methods.POST, params);
  },
  
  updateQuizQuestionFeedback:(key, params)=>{
    return createCall(getFullUrl(key), methods.PUT, params);
  },
  
  createQuizQuestionFeedback: (key, params)=>{
    return createCall(getFullUrl(key), methods.POST, params);
  },
  deleteQuizQuestion:(key, params)=>{
    return createCall(getFullUrl(key), methods.DELETE, params);
  }
};

const ResponseHandler = {
  getCustomers: async (response) => {
    return {
      data: response?.result?.customers,
      success: true,
    };
  },
  getRegion: async (response) => {
    return {
      data: response?.result,
      success: true,
    };
  },
  getPlantRegions: async (response) => {
    const { result, success } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't get regions list!",
      };
    }

    return {
      success: true,
      data: result?.regions || [],
      page: {
        current: result?.page || 1,
        count: result?.count || 0,
        offset: result?.offset || 0,
      },
    };
  },
  getRegions: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't get regions list!",
      };
    }

    return {
      data: result?.regions || [],
      page: {
        current: result?.page || 1,
        count: result?.count || 0,
        offset: result?.offset || 0,
      },
      success: true,
    };
  },
  unlinkRegionAndPlant: async (response) => {
    const { success } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't unlink  eco region and plant!",
      };
    }

    return {
      data: success,
      success: true,
      message: "Successfully unlinked",
    };
  },
  linkRegionAndPlant: async (response) => {
    const { success } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't link eco region and plant!",
      };
    }

    return {
      data: success,
      success: true,
      message: "Successfully linked",
    };
  },
  createRegion: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't create region!",
      };
    }

    return {
      data: result.eco_region_id,
      success: true,
      message: "Created new region!",
    };
  },
  deleteRegion: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't delete region!",
      };
    }

    return {
      data: result?.eco_region_id,
      success: true,
      message: "Successfully deleted!",
    };
  },
  updateRegion: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't update region!",
      };
    }

    return {
      data: result.eco_region_id,
      success: true,
      message: "Successfully updated",
    };
  },
  getAccount: async (response) => {
    return {
      data: response.result,
      success: true,
    };
  },
  getAccounts: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't get accounts list!",
      };
    }

    return {
      data: result?.accounts || [],
      ...result,
      success: true,
    };
  },
  createAccount: async (response) => {
    const { success, result, error } = response;
    if (!success) {
      return {
        success: false,
        message: error?.message || "Can't create account!",
      };
    }

    return {
      data: result,
      success: true,
      message: "Created new account!",
    };
  },
  deleteAccount: async (response) => {
    const { success, result, error } = response;
    if (!success) {
      return {
        success: false,
        message: error?.message || "Can't delete account!",
      };
    }

    return {
      data: result.member_id,
      success: true,
      message: "Successfully deleted!",
    };
  },
  updateAccount: async (response) => {
    const { success, result, error } = response;
    if (!success) {
      return {
        success: false,
        message: error?.message || "Can't update account!",
      };
    }

    return {
      data: result.member_id,
      success: true,
      message: "Successfully updated",
    };
  },
  resetPassword: async (response) => {
    const { success, result, error } = response;
    if (!success) {
      return {
        success: false,
        message: error?.message || "Can't reset password!",
      };
    }

    return {
      data: result,
      success: true,
      message: "Successfully done!",
    };
  },
  getPlant: async (response) => {
    return {
      data: response.result,
      success: true,
    };
  },
  getPlants: async (response) => {
    console.log('API Response:', response); // Ensure this logs the correct response
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't get plants list!",
      };
    }

    return {
      data: result,
      success: true,
    };
  },
  createPlant: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't create plant!",
      };
    }

    return {
      data: result.plant_id,
      success: true,
      message: "Created new plant, Section and Customer tabs are enabled!",
    };
  },
  deletePlant: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't delete plant!",
      };
    }

    return {
      data: result.plant_id,
      success: true,
      message: "Successfully deleted!",
    };
  },
  updatePlant: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't update plant!",
      };
    }

    return {
      data: result.plant_id,
      success: true,
      message: "Successfully updated",
    };
  },
  uploadImages: async (response) => {
    const { success } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't upload image!",
      };
    }

    return {
      data: success,
      success: true,
      message: "Successfully uploaded",
    };
  },
  uploadCSV: async (response) => {
    const { success } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't upload csv!",
      };
    }

    return {
      data: success,
      success: true,
      message: "Successfully uploaded",
    };
  },
  unlinkAccountAndCustomer: async (response) => {
    const { success } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't unlink account!",
      };
    }

    return {
      data: success,
      success: true,
      message: "Successfully unlinked",
    };
  },
  linkAccountAndCustomer: async (response) => {
    const { success } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't link account!",
      };
    }

    return {
      data: success,
      success: true,
      message: "Successfully linked",
    };
  },
  deleteContent: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't delete content!",
      };
    }

    return {
      data: result.content_id,
      success: true,
      message: "Successfully deleted!",
    };
  },

  toggleContentCustomer: async (response) => {
    const { success } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't unlink / link customer!",
      };
    }

    return {
      data: success,
      success: true,
      message: "Successfully linked",
    };
  },

  // QUIZ stuff
  getQuizQuestion: async (response) => {
    return {
      data: response.result,
      success: true,
    };
  },
  getQuizList: async (response) => {
    const { result } = response;
    return {
      data: result,
      success: true,
    };
  },
  addOrUpdateQuiz: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't update / create quiz!",
      };
    }

    return {
      data: result,
      success: true,
      message: "Successful request",
    };
  },
  getQuizQuestions: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't get quiz question list!",
      };
    }

    return {
      data: result,
      success: true,
    };
  },
  createQuizQuestion: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't create quiz question!",
      };
    }

    return {
      data: result.quiz_question_id,
      success: true,
      message:
        "Created new quiz question, Answer and Customer tabs are enabled!",
    };
  },
  deleteQuizQuestion: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't delete quiz question!",
      };
    }

    return {
      data: result.quiz_question_id,
      success: true,
      message: "Successfully deleted!",
    };
  },
  updateQuizQuestion: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't update quiz question!",
      };
    }

    return {
      data: result.quiz_question_id,
      success: true,
      // message: "Successfully updated",
    };
  },
  unlinkQuizQuestionAndCustomer: async (response) => {
    const { success } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't unlink customer!",
      };
    }

    return {
      data: success,
      success: true,
      message: "Successfully unlinked",
    };
  },
  linkQuizQuestionAndCustomer: async (response) => {
    const { success } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't update!",
      };
    }

    return {
      data: success,
      success: true,
      message: "Successfully updated",
    };
  },

  getLeaderboard: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't get Leaderboard",
      };
    }

    return {
      data: result.data,
      quiz: result.quiz,
      sorting: result.sorting,
      success: true,
    };
  },

  resetQuiz: async (response) => {
    const { success, error } = response;
    const message = error?.message ? error.message : "Can't reset quiz";
    return {
      success,
      message: success ? "Successful request" : message,
    };
  },

  // Quiz answers
  getQuizAnswer: async (response) => ({
    data: response.result,
    success: true,
  }),

  getQuizQuestionAnswers: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't get quiz answers list!",
      };
    }

    return {
      data: result,
      success: true,
    };
  },
  createQuizAnswer: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't create quiz answer!",
      };
    }

    return {
      data: result.quiz_answer_id,
      success: true,
      message: "Successfully created!",
    };
  },
  deleteQuizAnswer: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't delete quiz answer!",
      };
    }

    return {
      data: result.quiz_answer_id,
      success: true,
      message: "Successfully deleted!",
    };
  },
  updateQuizAnswer: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't update quiz answer!",
      };
    }

    return {
      data: result.quiz_answer_id,
      success: true,
      message: "Successfully updated",
    };
  },

  // Quiz Question Feedback
  getQuizQuestionFeedback: async (response) => ({
    data: response.result,
    success: true,
  }),
  createQuizQuestionFeedback: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't create quiz question feedback!",
      };
    }

    return {
      data: result.quiz_question_feedback_id,
      success: true,
      message: "Successfully created!",
    };
  },
  deleteQuizQuestionFeedback: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't delete quiz question feedback!",
      };
    }

    return {
      data: result.quiz_question_feedback_id,
      success: true,
      message: "Successfully deleted!",
    };
  },
  updateQuizQuestionFeedback: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't update quiz question feedback!",
      };
    }

    return {
      data: result.quiz_question_feedback_id,
      success: true,
      // message: "Successfully updated",
    };
  },

  // Quiz Content
  linkUnlinkQuizContentCustomer: async (response) => {
    const { success } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't make request!",
      };
    }

    return {
      data: success,
      success: true,
      message: "Done",
    };
  },

  createQuizContent: async (response) => ({
    success: response.success,
    message: response.success ? "Created!" : "Can't create.",
    data: response.result,
  }),

  updateQuizContent: async (response) => ({
    success: response.success,
    message: response.success ? "Updated!" : "Can't update data.",
    data: response.result,
  }),

  deleteQuizContent: async (response) => ({
    success: response.success,
    message: response.success ? "Deleted!" : "Can't delete data.",
  }),

  getQuizContent: async (response) => ({
    success: response.success,
    message: response.success ? null : "Can't fetch data.",
    data: response.result,
  }),

  getQuizContents: async (response) => ({
    success: response.success,
    message: response.success ? null : "Can't fetch data.",
    data: response.result,
  }),

  // Messages
  createMessage: async ({ success, result }, dispatcher) => {
    if (success) {
      dispatcher(sendMessageSuccess(result?.[0]));
    }

    return {
      success,
      data: result,
    };
  },

  deleteMessage: async({success, result}, dispatcher)=>{
    return {
      success,
      data: result,
    };
  },

  getNotificationImage: async({success, result}, dispatcher)=>{
    return {
      success,
      data: result,
    };
  },

  forwardMessage: async ({ success, result }) => ({
    success,
    data: result,
  }),

  getConversations: async (response) => ({
    success: response.success,
    message: response.success ? null : "Can't get conversation list.",
    data: response.result,
  }),

  readMessageThread: async (response) => ({
    success: response.success,
    message: response.success ? null : "Can't mark as read.",
  }),

  notify: async (response) => ({
    success: response.success,
    message: response.success ? null : "Unable to send push notification.",
  }),

  toggleGame: async (response) => ({
    success: response.success,
    message: response.success ? null : "Can't enable / disable game.",
  }),

  duplicateQuizQuestion: async (response) => {
    const { success, result } = response;
    if (!success) {
      return {
        success: false,
        message: "Can't duplicate the question!",
      };
    }

    return {
      data: result.quiz_question_id,
      success: true,
      message: "The question has been duplicated successfully",
    };
  },

  // Activity log
  getActivityLog: async (response) => ({
    data: response.result,
    success: response.success,
    message: response.error?.message,
  }),
  getPermissions: async (response) => {
    return {
      data: response?.result,
      success: response.success,
    };
  },
  togglePermission: async (response) => {
    const { success } = response;
    if (!success) {
      return {
        success: false,
        message: " Failed to change permission status!",
      };
    }

    return {
      data: success,
      success: true,
      message: "Permission status changed!",
    };
  },
};

export const makeAPICall = async (
  key,
  params,
  dispatcher = null,
  options = { loader: true }
) => {
  try {
    if (options.loader && dispatcher) {
      dispatcher(startLoading());
    }

    const token = getAuthenticationToken();
    const response = await proxy[key](key, params);

    const { data, success, message, ...props } = await ResponseHandler[key](
      response?.data,
      dispatcher
    );

    if (message && dispatcher) {
      if (!success) {
        dispatcher(showFailureNotification(message));
      } else {
        dispatcher(showSuccessNotification(message));
      }
    }

    if (options.loader && dispatcher) {
      dispatcher(finishLoading());
    }
    return {
      data,
      success,
      ...props,
    };
  } catch (error) {
    console.log(error)
    console.log("Error: ------ ", error, key);
    dispatcher(finishLoading());
    dispatcher(
      showFailureNotification(error.message || "Something went wrong")
    );

    return {
      data: null,
      success: false,
    };
  }
};

const ApiService = {
  getPlantSections: async (params, token) => {
    try {
      const response = await proxy.getPlantSections("getPlantSections", params);
      return response?.data?.result?.plantSections;
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
    }
  },
  createPlantSection: async (params, dispatch, token) => {
    let notification = {};

    try {
      const response = await proxy.createPlantSection(
        "createPlantSection",
        params
      );
      const { result, success } = response.data;
      if (success) {
        notification = dispatch(
          showSuccessNotification("Section created successfully!")
        );
      } else {
        notification = dispatch(
          showFailureNotification("Failed to create section!")
        );
      }

      return result?.section_id;
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
    }
  },
  updatePlantSection: async (params, dispatch, token) => {
    try {
      const response = await proxy.updatePlantSection(
        "updatePlantSection",
        params
      );
      const { result, success } = response.data;
      let notification = {};
      if (success) {
        notification = dispatch(
          showSuccessNotification("Section updated successfully!")
        );
      } else {
        notification = dispatch(
          showFailureNotification("Failed to update section!")
        );
      }

      return result?.section_id;
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
    }
  },
  deletePlantSection: async (params, token) => {
    try {
      const response = await proxy.deletePlantSection(
        "deletePlantSection",
        params
      );
      const { result } = response?.data;
      return result?.section_id;
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
    }
  },
  getContents: async (params, token) => {
    try {
      const response = await proxy.getContents("getContents", params);
      return response?.data?.result;
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
    }
  },
  getContentsByType: async (params, token) => {
    try {
      const response = await proxy.getContentsByType(
        "getContentsByType",
        params
      );
      return response?.data?.result.contents;
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
    }
  },
  getContentTypes: async (token, dispatch) => {
    try {
      const response = await proxy.getContentTypes("getContentTypes", {});

      const { result, success } = response.data;
      if (success) {
        dispatch(getContentTypesSuccess(result.contentTypes));
      }
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
    }
  },
  getContent: async (params, dispatch) => {
    try {
      const response = await proxy.getContent("getContent", params);

      const { result, success } = response.data;
      if (!success) {
        return dispatch(showFailureNotification("Unable to get content!"));
      }

      return result;
    } catch (error) {
      dispatch(showFailureNotification(`Erros: ${JSON.stringify(error)}`));
      console.log(error);
    }
  },
  createContent: async (params, dispatch) => {
    try {
      const response = await proxy.createContent("createContent", params);
      const { result, success } = response?.data;
      if (!success) {
        return dispatch(showFailureNotification(`Error while create content`));
      }
      dispatch(showSuccessNotification(`Successfully created`));
      return result.content_id;
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
      dispatch(showFailureNotification(`Error: ${JSON.stringify(error)}`));
    }
  },
  updateContent: async (params, dispatch) => {
    try {
      const response = await proxy.updateContent("updateContent", params);
      const { result, success } = response?.data;
      if (!success) {
        return dispatch(
          showFailureNotification(`Error while updating content`)
        );
      }
      dispatch(showSuccessNotification(`Successfully updated`));
      return result.content_id;
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
      dispatch(showFailureNotification(`Error: ${JSON.stringify(error)}`));
    }
  },
  deleteImage: async (params, dispatch, urlKey) => {
    try {
      const response = await proxy.deleteImage(urlKey, params);
      const { success } = response?.data;
      if (!success) {
        return dispatch(showFailureNotification(`Error while deleting image`));
      }
      dispatch(showSuccessNotification(`Deleted`));
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
      dispatch(showFailureNotification(`Error: ${JSON.stringify(error)}`));
    }
  },
  updateImageInfo: async (params, dispatch, urlKey) => {
    try {
      const response = await proxy.updateImageInfo(urlKey, params);
      const { success } = response?.data;
      if (!success) {
        return dispatch(showFailureNotification(`Error while updating image`));
      }
      dispatch(showSuccessNotification(`Updated!!`));
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
      dispatch(showFailureNotification(`Error: ${JSON.stringify(error)}`));
    }
  },
  createCustomer: async (params, dispatch) => {
    try {
      const response = await proxy.createCustomer("createCustomer", params);
      const { result, success } = response?.data;
      if (!success) {
        return dispatch(showFailureNotification(`Error while create customer`));
      }
      dispatch(showSuccessNotification(`Successfully created`));
      return result.customer_id;
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
      dispatch(showFailureNotification(`Error: ${JSON.stringify(error)}`));
    }
  },
  deleteCustomer: async (params, dispatch) => {
    try {
      const response = await proxy.deleteCustomer("deleteCustomer", params);
      const { success } = response.data;
      if (!success) {
        return dispatch(
          showFailureNotification(`Error while deleting customer`)
        );
      }
      dispatch(showSuccessNotification(`Deleted`));
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
    }
  },
  getCustomer: async (params, dispatch) => {
    try {
      const response = await proxy.getCustomer("getCustomer", params);

      const { result, success } = response.data;
      if (!success) {
        return dispatch(showFailureNotification("Unable to get customer!"));
      }

      return result;
    } catch (error) {
      dispatch(showFailureNotification(`Erros: ${JSON.stringify(error)}`));
      console.log(error);
    }
  },
  updateCustomer: async (params, dispatch) => {
    try {
      const response = await proxy.updateCustomer("updateCustomer", params);
      const { result, success } = response?.data;
      if (!success) {
        return dispatch(
          showFailureNotification(`Error while updating customer`)
        );
      }
      // dispatch(showSuccessNotification(`Successfully updated`));
      return result.customer_id;
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
      dispatch(showFailureNotification(`Error: ${JSON.stringify(error)}`));
    }
  },
  getCustomers: async (params, dispatch) => {
    try {
      const response = await proxy.getCustomers("getCustomers", params);

      const { result, success } = response.data;
      if (!success) {
        return dispatch(
          showFailureNotification("Unable to get customers list!")
        );
      }

      return result;
    } catch (error) {
      dispatch(showFailureNotification(`Erros: ${JSON.stringify(error)}`));
      console.log(error);
    }
  },
  getPlantCustomers: async (params, dispatch) => {
    try {
      const response = await proxy.getPlantCustomers(
        "getPlantCustomers",
        params
      );

      const { result, success } = response.data;
      if (!success) {
        return dispatch(
          showFailureNotification("Unable to get customers list!")
        );
      }

      return result.customers;
    } catch (error) {
      dispatch(showFailureNotification(`Erros: ${JSON.stringify(error)}`));
      console.log(error);
    }
  },
  unlinkPlantFromCustomer: async (params, dispatch) => {
    try {
      const response = await proxy.unlinkPlantFromCustomer(
        "unlinkPlantFromCustomer",
        params
      );

      const { success } = response.data;
      if (!success) {
        return dispatch(
          showFailureNotification("Unable to unlink customer and plant!")
        );
      }

      return success;
    } catch (error) {
      dispatch(showFailureNotification(`Erros: ${JSON.stringify(error)}`));
      console.log(error);
    }
  },
  linkPlantToCustomer: async (params, dispatch) => {
    try {
      const response = await proxy.linkPlantToCustomer(
        "linkPlantToCustomer",
        params
      );

      const { success } = response.data;
      if (!success) {
        return dispatch(
          showFailureNotification("Unable to unlink customer and plant!")
        );
      }

      return success;
    } catch (error) {
      dispatch(showFailureNotification(`Erros: ${JSON.stringify(error)}`));
      console.log(error);
    }
  },

  duplicatePlant: async (params, dispatch) => {
    try {
      const { data } = await proxy.duplicatePlant("duplicatePlant", params);

      // const { success } = response.data;
      if (!data.success) {
        return dispatch(
          showFailureNotification("Unable to duplicate the plant.")
        );
      }
      return data?.result;
    } catch (error) {
      dispatch(showFailureNotification(`Erros: ${JSON.stringify(error)}`));
      console.log(error);
    }
  },

  getConversations: async (params, dispatch) => {
    try {
      const result = await proxy.getConversations("getConversations", params);
      return result;
    } catch (error) {
      dispatch(showFailureNotification(`Erros: ${JSON.stringify(error)}`));
      console.log(error);
    }
  },
  
  deleteMessage: async (params, dispatch)=>{
    try{
      const result = await proxy.deleteMessage("deleteMessage", params);
      return result;
    }catch(error){
      dispatch(showFailureNotification(`Erros: ${JSON.stringify(error)}`));
      console.log(error)
    }
  },
  getNotificationImage: async (params, dispatch)=>{
    try{
      const result = await proxy.getNotificationImage("getNotificationImage", params);
      return result;
    }catch(error){
      dispatch(showFailureNotification(`Erros: ${JSON.stringify(error)}`));
      console.log(error)
    }
  },
  getPermissions: async (params, dispatch) => {
    try {
      const response = await proxy.getPermissions("getPermissions", params);

      const { result, success } = response.data;
      if (!success) {
        return dispatch(
          showFailureNotification("Unable to get permission list!")
        );
      }

      return result;
    } catch (error) {
      dispatch(showFailureNotification(`Erros: ${JSON.stringify(error)}`));
      console.log(error);
    }
  },
  togglePermission: async (params, dispatch) => {
    try {
      const response = await proxy.togglePermission("togglePermission", params);
      const { result, success } = response?.data;
      if (!success) {
        return dispatch(
          showFailureNotification(`Error while toggling permission`)
        );
      }
      // dispatch(showSuccessNotification(`Successfully updated`));
      return result.customer_id;
    } catch (error) {
      if (error?.data?.error) {
        alert(error?.data?.error.message);
      }
      dispatch(showFailureNotification(`Error: ${JSON.stringify(error)}`));
    }
  },
};

export default ApiService;
