import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import GrowWithTrees from 'components/GrowWithTrees';

const Public = () => {


  return (
    <>
      <Switch>
        <Route exact path="/error">
          <GrowWithTrees />
        </Route>
        <Redirect to="/error" />
      </Switch>
    </>
  );
}

export default Public;
