import React from "react";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { getAccount } from "store/selectors";

const User = () => {
  const account = useSelector(getAccount);

  return (
    <div className='text-secondary'>
      <div>
        <FontAwesomeIcon icon={faUserAlt} />
        &nbsp; {account.name}
      </div>
      <div>
        <FontAwesomeIcon icon={faEnvelope} />
        &nbsp; {account.email}
      </div>
    </div>
  );
};

export default User;
