import ReactDOM from "react-dom";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getLoadState } from "store/selectors";
import classes from "./index.module.scss";

const loadingBarNode = document.getElementById("loading-bar-root");

const LoadingBar = ({ variant = "success" }) => {
  const loading = useSelector(getLoadState);

  if (!loading) return false;

  return ReactDOM.createPortal(
    <div className={classes.loadingBarOverlay}>
      <Spinner animation="border" role="status" variant={variant}>
        {/* <span className="visually-hidden">Loading...</span> */}
      </Spinner>
    </div>,
    loadingBarNode
  );
};

export default LoadingBar;
