import ACTIONS from "store/actionTypes";

export const showNotification = ({ message, appearance }) => ({
  type: ACTIONS.SHOW_NOTIFICATION,
  message,
  appearance,
});

export const showSuccessNotification = (message) => ({
  type: ACTIONS.SHOW_NOTIFICATION_SUCCESS,
  message,
});

export const showFailureNotification = (message) => ({
  type: ACTIONS.SHOW_NOTIFICATION_FAILURE,
  message,
});

export const resetNotification = () => ({
  type: ACTIONS.RESET_NOTIFICATION,
});
