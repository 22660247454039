import { all, call } from "redux-saga/effects";
import { watchLogin, watchLogout } from "./authentication";
import { fetchMessagesByInterval, watchLoginToFetchMessages } from "./messages";
import { watchPermissions } from "./permissions";

export default function* rootSaga() {
  yield all([
    call(watchLogin),
    call(watchLogout),
    call(fetchMessagesByInterval),
    call(watchLoginToFetchMessages),
    call(watchPermissions),
  ]);
}
