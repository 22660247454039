import actionTypes from "../actionTypes";

export const successMessages = ({ messages }) => ({
  type: actionTypes.GET_MESSAGES_SUCCESS,
  messages,
});

export const sendMessageSuccess = (message) => ({
  type: actionTypes.SEND_MESSAGE_SUCCESS,
  message,
});

export const getMessages = () => ({
  type: actionTypes.GET_MESSAGES_REQUEST,
});
