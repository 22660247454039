import ACTIONS from "store/actionTypes";

export function getPermissionsSuccess({ permissions }) {
  return {
    type: ACTIONS.GET_PERMISSIONS_SUCCESS,
    permissions,
  };
}

export function getPermissionsFailure(e) {
  return {
    type: ACTIONS.GET_PERMISSIONS_FAILURE,
    exception: e,
  };
}
