import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import * as reducers from "store/reducers";
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'store/sagas/root';

const storeFactory = ( initialState = {} ) => {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        combineReducers(reducers),
        initialState,
        composeWithDevTools(applyMiddleware(sagaMiddleware)),
    );
    // initSagas(sagaMiddleware);
    sagaMiddleware.run(rootSaga);

    return store;
}


export default storeFactory;
