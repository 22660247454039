import ACTIONS from "store/actionTypes";

export const loginRequest = (password, username) => ({
  type: ACTIONS.ACCOUNT_REQUEST,
  password,
  username,
});

export const loginSuccess = ({
  account,
  token,
  authenticated,
  permissions,
}) => ({
  type: ACTIONS.LOGIN_SUCCESS,
  authData: {
    account,
    permissions,
    token,
    authenticated,
  },
});

export const loginFail = ({ exception }) => ({
  type: ACTIONS.LOGIN_FAILURE,
  exception,
});

export const loginWithToken = (token) => ({
  type: ACTIONS.LOGIN_REQUEST,
  token,
});

export const logout = () => ({
  type: ACTIONS.LOGOUT,
});

export const updateInfo = (data) => ({
  type: ACTIONS.UPDATE_INFO,
  data,
});
