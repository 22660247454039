import { createSelector } from "reselect";

const authData = (state) => state.authData;
const contentTypes = (state) => state.contentTypes;
const notification = (state) => state.notification;
const loading = (state) => state.loading;
const messages = (state) => state.messages;

export const getAuthenticationToken = createSelector(authData, (a) => a.token);

export const getAuthenticationStatus = createSelector(
  authData,
  (a) => a.authenticated
);

export const getAccount = createSelector(authData, (a) => a?.account);
export const getPermissions = createSelector(authData, (a) => a?.permissions);
export const getAccountRole = createSelector(
  authData,
  (a) => a?.account?.role_id
);

export const getContentTypes = createSelector(contentTypes, (a) => a?.list);
export const getNotification = notification;
export const getLoadState = loading;

export const getMessages = messages;
export const getUnreadMessagesCount = createSelector(messages, (msgs) =>
  msgs?.reduce((sum, thread) => (sum += thread.unreads), 0)
);
