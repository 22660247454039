const ACTIONS = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT: "LOGOUT",
  UPDATE_INFO: "UPDATE_INFO",
  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
  SHOW_NOTIFICATION_SUCCESS: "SHOW_NOTIFICATION_SUCCESS",
  SHOW_NOTIFICATION_FAILURE: "SHOW_NOTIFICATION_FAILURE",
  RESET_NOTIFICATION: "RESET_NOTIFICATION",
  CONTENT_TYPES_REQUEST: "CONTENT_TYPES_REQUEST",
  CONTENT_TYPES_SUCCESS: "CONTENT_TYPES_SUCCESS",
  CONTENT_TYPES_FAILURE: "CONTENT_TYPES_FAILURE",
  LOADING_START: "LOADING_START",
  LOADING_FINISH: "LOADING_FINISH",
  GET_PERMISSIONS_SUCCESS: "GET_PERMISSIONS_SUCCESS",
  GET_PERMISSIONS_FAILURE: "GET_PERMISSIONS_FAILURE",

  // messages
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  GET_MESSAGES_REQUEST: "GET_MESSAGES_REQUEST",
  GET_MESSAGES_SUCCESS: "GET_MESSAGES_SUCCESS",
};

export default ACTIONS;
