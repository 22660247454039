import axios from "axios";
import { apiUrl, apiRoutes } from "configs/parameters";
const url = (route) => `${apiUrl}${apiRoutes[route]}`;

export function callApi(action, method, options, headers) {
  const axiosConfig = {
    method,
    headers: { 'Application-Name': "GWT-ADMIN", ...headers},
    url: url(action),
  };

  if (options) {
    if (method === "GET") {
      axiosConfig.params = options;
    } else {
      axiosConfig.data = options;
    }
  }

  return axios(axiosConfig)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}
