const LocalStorage = {
    empty: () => {
        localStorage.clear();
    },
    set: (key, value) => {
        localStorage[key] = value;
    },
    get: (key) => {
        return localStorage.getItem(key);
    }
}

export default LocalStorage;
